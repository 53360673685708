<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:readOnly="readOnly"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>

<!--<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:navigableDetail="true"
		></pui-datatable>-->

		<equipamiento-modals
			:modelName="modelName"
		></equipamiento-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import equipamientoActions from './EquipamientoActions';
import equipamientoModals from './EquipamientoModals.vue';

export default {
	name: 'equipamiento-grid',
	components: {
		'equipamiento-modals': equipamientoModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'equipamiento',
			actions: equipamientoActions.gridactions,
			modelColumnDefs: {
				matesiso: {
					render: (data) => {
                        if (data === 'S') {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
                        } else {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
                        }
					}
				}
			},
			masterDetailColumnVisibles:{
				numdoc:false,
				estado:false,
				tipoman:false,
				entrada:false,
				fecentmer:false,
				salida:false,
				fecsalmer:false,
				fecinicioaut:false,
				idnotifequip:true,
				idnotif:false,
				matricula:true,
				matesiso:true,
				pesobruto:true,
				idtipequip:false,
				idtiptamequip:false,
				idgruequip:false,
				fecalta:false,
				usralta:false,
				fecmodif:false,
				usrmodif:false,
				codtipequipedi:false,
				lang:false,
				tipoequipamiento:true,
				codgruequedi:false,
				grupajeequipamiento:true,
				codtiptamedi:false,
				tipytamequipamiento:true,
				idpuertolocal:false
			}
		}
	}
}
</script>
